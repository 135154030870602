<template>
  <div>
    <h1>你画我猜</h1>
    <canvas ref="canvas" width="400" height="400"></canvas>
    <div>
      <label>画笔粗细：</label>
      <select v-model="lineWidth">
        <option value="1">细</option>
        <option value="5">中</option>
        <option value="10">粗</option>
      </select>
    </div>
    <div>
      <label>画笔颜色：</label>
      <input type="color" v-model="strokeColor">
    </div>
    <input type="text" v-model="guess" placeholder="猜一下是什么" @keyup.enter="checkGuess">
    <button @click="clearCanvas">清空</button>
    <div>
      <label>选择形状：</label>
      <select v-model="selectedShape">
        <option value="line">直线</option>
        <option value="circle">圆圈</option>
      </select>
    </div>
    <div>
      <h2>聊天窗口</h2>
      <ul>
        <li v-for="message in messages">{{ message }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawing: false,
      lastX: 0,
      lastY: 0,
      guess: '',
      lineWidth: '1',
      strokeColor: '#000000',
      selectedShape: 'line',
      messages: []
    };
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext('2d');

    this.canvas.addEventListener('mousedown', this.startDrawing);
    this.canvas.addEventListener('mousemove', this.draw);
    this.canvas.addEventListener('mouseup', this.stopDrawing);
  },
  methods: {
    startDrawing(e) {
      this.drawing = true;
      this.lastX = e.offsetX;
      this.lastY = e.offsetY;
    },
    draw(e) {
      if (this.drawing) {
        this.ctx.beginPath();
        this.ctx.moveTo(this.lastX, this.lastY);
        this.ctx.lineTo(e.offsetX, e.offsetY);
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.strokeStyle = this.strokeColor;
        this.ctx.stroke();
        this.lastX = e.offsetX;
        this.lastY = e.offsetY;
      }
    },
    stopDrawing() {
      this.drawing = false;
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    checkGuess() {
      if (this.guess.toLowerCase() === 'apple') {
        this.messages.push('猜对了！');
      } else {
        this.messages.push('猜错了，请继续画');
      }
    },
  },
};
</script>

<style scoped>
canvas {
  border: 1px solid black;
}
</style>